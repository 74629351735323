import styled from "styled-components";

export const Wrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AmountWrapper = styled.div`
  width: 500px;

  @media(max-width: 767px) {
    width: 100%;
  }
`

export const Heading = styled.h1`
  font-family: Athletics;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: center;
`;

export const InputLabel = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
`;

export const RateBox = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 14px;
    background: #f9f9f9;
    border-radius: 8px;

    .item-content {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    
    
`;

export const InfoBox = styled.span`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;

  .item-content {
    font-weight: 600;
  }
`;

export const FormWrapper = styled.div`
    display: flex;
    gap: 45px;
    flex-direction: column;
    margin-top: 40px;
    width: 500px;

    @media(max-width: 768px) {
      width: 100%;
    }
`;

export const ReviewBox = styled.div`
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #E8E8E8;
    padding: 20px;
    min-width: 70%;;
`

export const ItemFlex = styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E8E8E8;

`

export const BoxTitle = styled.p`
font-family: Inter;
font-size: 18px;
font-weight: 500;
line-height: 28px;
text-align: left;
`

export const EditBtn = styled.p`
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    color: #EF2C5A;
    cursor: pointer;

`

export const Details = styled.section`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .text-right {
        text-align: right;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .text-left {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #757575;

    }
    
    .m-item{
        margin-left: 8px;
        margin-right: 8px;
    }
`