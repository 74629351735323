import React from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  Wrapper,
  Header,
  Inputs,
  InputBox,
  ButtonsDiv,
  SubHeader,
  Label,
} from "./style";
import CustomSelect from "../../common/CustomSelect";
import { InputNumber } from "antd";
import { Button } from "../../common/Button";
import { FormInputs, PayoutModalProps } from "./types";
import ErrorMessage from "../../ErrorMessage";

const PayoutModal = ({
  accounts,
  onPreview,
  onCloseModal,
  setRequestData,
  getRequestFee,
  businessCurrency,
  minimumPayoutValue,
}: PayoutModalProps) => {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormInputs>();
  const watchAll = watch();

  const onCancel = () => {
    reset(
      {
        accounts: null,
        amount: null,
      },
      {
        keepErrors: true,
        keepTouched: false,
      },
    );
    onCloseModal();
  };

  const onSubmit: SubmitHandler<FormInputs> = async (formData) => {
    setRequestData(formData);
    await getRequestFee();

    onPreview();
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Header>Payout request</Header>
      <SubHeader>Payouts typically take 1-3 working days, if you're a starter business the payout limit is 400,000 NGN or equivalent.</SubHeader>

      <Inputs>
        <InputBox>
          <Controller
            name="accounts"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <CustomSelect
                $height="40px"
                $width="100%"
                field={field}
                placeholder="Bank account"
                options={accounts}
                defaultValue={null}
              />
            )}
          />
          {errors.accounts?.type === "required" && (
            <ErrorMessage message="Field is required" />
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="amount"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputNumber
                prefix={
                  <Label>
                    <span className={`currency-flag currency-flag-${businessCurrency.toLowerCase()}`}/>
                    <span>{businessCurrency}</span>
                  </Label>
                }
                {...field}
                placeholder="Amount"
                onChange={(value) => {
                  field.onChange(value);
                }}
                formatter={(value) =>
                  `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            )}
          />
          {errors.amount?.type === "required" && (
            <ErrorMessage message="Field is required" />
          )}
          
        </InputBox>
      </Inputs>
      <ButtonsDiv>
        <Button
          label="Cancel"
          type="button"
          theme="alternate"
          height="40px"
          fontSize="12px"
          onClick={onCancel}
        />
        <Button
          label="Request"
          type="submit"
          theme="secondary"
          height="40px"
          fontSize="12px"
          
        />
      </ButtonsDiv>
    </Wrapper>
  );
};

export default PayoutModal;
