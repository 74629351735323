import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import {usePostHog} from "posthog-js/react";
import {useWindowSize} from "@reach/window-size";
import dayjs from "dayjs";
import {getCountry} from "country-currency-map";
import {
    BoxBody,
    EmptyWrapper,
    EmptyWrapperTitle,
    EmptyWrapperSubTitle,
    MobileBody,
    TitleParagraph,
    Wrapper,
    BreakDown,
    InfoTrigger
} from "./style";
import CustomTable from "../../components/common/CustomTable";
import {
    tableColumns,
    allCollectionsColumn,
    exportToXLSBalance,
} from "./utils";
import TitleFilter from "../../components/balances/TitleFilter";
import {
    useAppDispatch,
    useAppSelector,
} from "../../app/hooks";
import {
    defaultExchangeRate,
    filteredPayoutRequests,
    settlementFeeByCountry,
    settlementsBalance,
    settlementsInfo,
    settlementsPaid,
    settlementsPendingBalance,
} from "./settlementsSlice";
import {convertArrayToObject} from "../../utils/format";
import Loader from "../../components/common/Loader";
import {
    formatCurrencyAmount,
    formatCurrencyCodeAmount,
} from "../../utils/currency";
import CustomModal from "../../components/common/CustomModal";
import PayoutModal from "../../components/balances/PayoutModal";
import {NetworkErrorAlertContext} from "../../context/NetworkErrorAlert";
import BalanceListCard from "../../components/balances/BalanceListCard";
import CollectionBalance from "./components/CollectionBalance";
import Collections from "./components/Collections";
import PreviewPayout from "../../components/balances/PreviewPayout";
import PayoutOTP from "../../components/balances/PayoutOTP";
import EmptyMobileCard from "../../components/emptyStates/EmptyMobileCard";
import {ReactComponent as EmptyTableIcon} from "../../components/balances/assets/emptyTable.svg";
import {getallCollectionsBreakdown, getCollectionBreakDown, getFilteredPayoutRequests, requestPayout} from "./settlementsAPI";
import {formatLongString} from "../../utils/formatString";
import DrawerContainer from "../../components/common/Drawer/Drawer";
import TransactionDetails from "./TransactionDetails";
import CustomerDetails from "./CustomerDetails";
import {SuccessAlertContext} from "../../context/SuccessAlertContext";
import {selectUserRoleAndPermissions} from "../Settings/Team/redux/slice";
import {encryptAlgorithm} from "../../utils/encryptionAlgorithm";
import CustomMobileBalanceTable from "./components/CustomMobileBalanceTable";
import CollectionSummaryCard from "./components/CollectionsSummaryCard";
import { ReactComponent as QuestionMark } from '../../assets/icons/questionMark.svg'
import Tooltip from "../../components/common/Tooltip";
import InfoModal from "./components/InfoModal";

interface Props {
    title: string;
    subtitle: string;
}

const EmptyBalanceDescription = ({
                                     title,
                                     subtitle,
                                 }: Props) => {
    return (
        <EmptyWrapper>
            <EmptyWrapperTitle>{title}</EmptyWrapperTitle>
            <EmptyWrapperSubTitle>
                {subtitle}
            </EmptyWrapperSubTitle>
        </EmptyWrapper>
    );
};

const Balances = () => {
    const posthog = usePostHog();
    const {width} = useWindowSize();
    const [bankId, setBankId] = useState("");
    const successAlertContext = useContext(
        SuccessAlertContext,
    );
    const {role, permissions} = useAppSelector(
        selectUserRoleAndPermissions,
    );

    const hasDownloadPermission = permissions?.[
        "collections"
        ]?.includes("EXPORT_COLLECTION_STATEMENT");
    const hasPayoutPermission = permissions?.[
        "collections"
        ]?.includes("CREATE_COLLECTION_PAYOUT");

    const {onShowAlert: onSuccess} =
    successAlertContext || {};
    const dispatch = useAppDispatch();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isFiltering, setIsFiltering] = useState(false);
    const [loading, setLoading] = useState(false);
    const [settlementsData, setSettlementsData] = useState(
        [],
    );
    const [search, setSearch] = useState("");
    const [accountSelectOptions, setAccountSelectOptions] =
        useState([]);
    const [accountMap, setAccountMap] = useState<any>({});
    const [showModal, setShowModal] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    const [showOTP, setShowOTP] = useState(false);
    const [allCollection, setAllCollection] = useState([]);
    const [exportCollectionData, setExportCollectionData] =
        useState(null);
    const [requestData, setRequestData] = useState(null);
    const [selectedStatus, setSelectedStatus] =
        useState("9");
    const [filterDate, setFilterDate] = useState([]);
    const [activeToggle, setActiveToggle] =
        useState("Collections");
    const [transactionDetail, setTransactionDetail] =
        useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10)
    const [collectionsPageNumber, setCollectionsPageNumber] = useState(1)
    const [collectionPageSize, setCollectionPageSize] = useState(10)
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [minimumPayoutValue, setMinimumPayoutValue] =
        useState(null);
    const [fee, setFee] = useState(null);
    const {userId, businessId} = useAppSelector(
        (state) => state.users,
    );

    const {bankAccounts, businessCurrency, business} =
        useAppSelector((state) => state.settings);
    const {
        status: settlementStatus,
        walletId,
        payoutRequests,
        settlementBalance,
        availableCollectionBalance,
        ledgerCollectionBalance,
        todayCollectionBalance,
        collectionHoldBalance,
    } = useAppSelector((state) => state.settlements);

    const {onShowAlert: onShowErrorAlert} = useContext(
        NetworkErrorAlertContext,
    );

    const getMinimumPayoutValue = async () => {
        try {
            const exValue = await dispatch(
                defaultExchangeRate({
                    sourceCurrency: "USD",
                    amount: 500,
                    destinationCurrency: businessCurrency,
                }),
            ).unwrap();
            setMinimumPayoutValue(exValue?.amount / 100);
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (businessCurrency) {
            getMinimumPayoutValue();
        }
    }, [businessCurrency, userId]);

    const onRequestClick = () => {
        if (hasPayoutPermission || role === "OWNER") {
            if (business && business?.approved === false) {
                onShowErrorAlert(
                    Error,
                    "Business awaiting approval.",
                );
            } else if (bankAccounts.length < 1) {
                onShowErrorAlert(
                    Error,
                    "Please add a bank account",
                );

                posthog?.capture("request_payout_b2b", {
                    status: "failure",
                    failure_reason: "No bank account",
                    currency: businessCurrency,
                });
            } else {
                setShowModal(true);
                posthog?.capture("request_payout_b2b", {
                    status: "success",
                    currency: businessCurrency,
                });
            }
        } else {
            onShowErrorAlert("", "Access is denied");
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const getQueryFields = () => {
        const queryFields: any = {};
        if (filterDate.length && filterDate[0].length > 0) {
            const [startDay, endDay] = filterDate;
            queryFields.startDay = startDay;
            queryFields.endDay = endDay;
        }
        if (selectedStatus !== "9") {
            queryFields.payoutStatus =
                Number(selectedStatus);
        }
        return queryFields;
    };

    const onPageChange = (page: number, size: number) => {
        setPageNumber(page);
        setPageSize(size)
    };

    const onCollectionsPageChange = (page: number, size: number) => {
        setCollectionsPageNumber(page)
        setCollectionPageSize(size)
    }

    const onStatusChange = (value) => {
        setSelectedStatus(value);
        onPageChange(1, pageSize);
    };

    const onDateChange = (date, dateString) => {
        setFilterDate(dateString);
        onPageChange(1, pageSize);
    };

    const getFilteredData = async () => {
        const filterPayload = getQueryFields();
        try {
            await dispatch(
                filteredPayoutRequests({
                    filter: {
                        businessId,
                        pageNumber,
                        walletId,
                        ...filterPayload,
                    },
                }),
            );
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (businessId && walletId) {
            getFilteredData();
        }
    }, [businessId, walletId, pageNumber]);

    const getFeeType = (currency: string) => {
        const merchantData = accountSelectOptions
            ? accountSelectOptions.find(
                (account) =>
                    account.value ===
                    requestData?.accounts,
            )
            : {};
        const country =
            merchantData?.country?.length > 3
                ? getCountry(merchantData?.country)
                    ?.abbreviation
                : merchantData?.country;

        if (currency !== "NGN" && country !== "NG") {
            return "bankTransfer";
        } else {
            return "merchantBankTransferFee";
        }
    };

    const getRequestFee = async () => {
        try {
            setLoading(true);
            const countryCode: string =
                business.country.length > 3
                    ? getCountry(business.country)
                          ?.abbreviation
                    : business.country;

            const countryFee = await dispatch(
                settlementFeeByCountry({
                    countryCode,
                    feeType: getFeeType(businessCurrency),
                }),
            ).unwrap();
            if (countryFee) {
                setFee(countryFee.fees);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };


    const onPreview = () => {
        setShowModal(false);
        setShowPreview(true);
    };

    const onRequestPayout = async () => {

        try {
            setLoading(true)
            const requestPayload = {
                walletId,
                payoutAmount: requestData
                    ? requestData.amount
                    : "",
                selectedAccount: requestData
                    ? requestData.accounts
                    : "",
                businessId,
                fee: fee || null,
                bankAccount: bankId
            };

            const encryptedData =
                encryptAlgorithm(requestPayload);

            const payload = {
                message: encryptedData,
            };

            await requestPayout(payload),

                posthog?.capture("otp_payout_b2b", {
                    status: "success",
                    currency: businessCurrency,
                });
            setLoading(false);
            onSuccess(
                "Your request has been sent successfully",
            );
            window.location.reload();
        } catch (err) {
            let theErrorMessage;

            if (err.error === "Pending Withdraw") {
                theErrorMessage =
                    "You have a pending payout, please wait till it is completed.";
            } else if (
                err.error === "Insufficient Balance"
            ) {
                theErrorMessage =
                    "Insufficient balance. Please top up your account.";
            } else {
                theErrorMessage = "The request failed.";
            }
            onShowErrorAlert(err, theErrorMessage);

            posthog?.capture("otp_payout_b2b", {
                status: "failure",
                failure_reason: theErrorMessage,
                currency: businessCurrency,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (businessId && userId) {
            dispatch(
                settlementsBalance({
                    businessId,
                    businessCurrency,
                }),
            );
            dispatch(
                settlementsPendingBalance({businessId}),
            );
            dispatch(settlementsPaid({businessId}));
        }
    }, [businessId, userId]);

    const onFilterButtonClick = () => {
        if (activeToggle === "Payout") {
            getFilteredData();
        } else if (activeToggle === "Collections") {
            getAllCollection();
        }
    };

    const onResetButtonClick = async () => {
        setIsFiltering(true);
        setFilterDate([]);
        await dispatch(
            filteredPayoutRequests({
                filter: {
                    businessId,
                    walletId,
                    status: selectedStatus,
                },
            }),
        );

        setIsFiltering(false);
    };

    useEffect(() => {
        const mercAccounts = bankAccounts?.map((acc) => ({
            value: acc.id,
            label: `${acc.accountNumber} - ${acc.bankName}`,
            accName: acc.accountName,
            accNumber: acc.accountNumber,
            bankName: acc.bankName,
            country: acc.country,
        }));
        const mercAccountsMapping = bankAccounts?.map(
            (acc) => ({
                accNo: acc.accountNumber,
                accBank: acc.bankName,
                accName: acc.accountName,
                id: acc.id,
            }),
        );

        const mercAccountsMappingToObj =
            convertArrayToObject(mercAccountsMapping, "id");
        setAccountMap(mercAccountsMappingToObj);
        setAccountSelectOptions(mercAccounts);
    }, [bankAccounts]);

    useEffect(() => {
        if (walletId) {
            dispatch(settlementsInfo({walletId}));
        }
    }, [walletId]);

    const allPayoutData = () => {
        setSettlementsData([]);
        if (
            payoutRequests?.length &&
            Object.keys(accountMap)?.length
        ) {
            const tableData = payoutRequests.map(
                (payout) => ({
                    key: payout.merchantWithdrawRequest.id,
                    payoutid:
                    payout.merchantWithdrawRequest
                        .requestId,
                    accBank:
                        accountMap[
                            payout?.merchantWithdrawRequest
                                ?.bankAccount
                            ]?.accBank ?? "-",
                    accNo:
                        accountMap[
                            payout?.merchantWithdrawRequest
                                .bankAccount
                            ]?.accNo ?? "-",
                    accName:
                        accountMap[
                            payout?.merchantWithdrawRequest
                                ?.bankAccount
                            ]?.accName ?? "-",
                    amount: formatCurrencyAmount(
                        payout.merchantWithdrawRequest
                            ?.wallet?.currency,
                        payout.merchantWithdrawRequest
                            .amount,
                    ),
                    status: payout.merchantWithdrawRequest
                        .payoutStatus,
                    date: dayjs(
                        payout.merchantWithdrawRequest
                            .createdAt,
                    ).format("DD.MM.YYYY"),
                }),
            );
            if (!businessId) {
                return null;
            } else {
                setSettlementsData(tableData);
            }
        }
    };

    const getAllCollection = async () => {
        const startDate = filterDate[0];
        const endDate = filterDate[1];
        const data = {
            business: businessId,
            startDay: startDate,
            endDay: endDate,
            pageNumber: collectionsPageNumber,
            pageSize: collectionPageSize,
        };

        try {
            if (!businessId) {
                return null;
            } else {
                setLoading(true);
                await getallCollectionsBreakdown(data).then(
                    (data) => {
                        const payInData =
                            data?.transactionSplit.map(
                                (item) => ({
                                    key: item.ref,
                                    ref: formatLongString(
                                        item.transaction
                                            .tnxRef,
                                        20,
                                    ),
                                    transaction_id:
                                    item.transaction
                                        .tnxRef,
                                    merchant_amount:
                                        formatCurrencyAmount(
                                            item.transaction
                                                .sourceCurrency,
                                            item.transaction
                                                .sourceAmount,
                                        ) || "--",
                                    payer_amount:
                                        formatCurrencyAmount(
                                            item.transaction
                                                .currency,
                                            Number(
                                                item
                                                    .transaction
                                                    .chargedAmount,
                                            ) / 100,
                                        ) || "--",
                                    currency:
                                        item.transaction
                                            .settledCurrency ||
                                        "--",
                                    source:
                                        item.settledAmount ||
                                        "--",
                                    rate: item.transaction
                                        .rate,
                                    date:
                                        dayjs(
                                            item.transaction
                                                .createdAt,
                                        ).format(
                                            "DD.MM.YYYY - HH:mm",
                                        ) || "--",
                                    status: item
                                        ?.transaction
                                        ?.toReview
                                        ? "In review"
                                        : item?.transaction
                                            ?.status,
                                    customer:
                                    item.transaction
                                        .customer,
                                    settlement_amount:
                                        formatCurrencyAmount(
                                            item.transaction
                                                .settledCurrency,
                                            item.transaction
                                                .settledAmount,
                                        ) || "-",
                                    amount_less:
                                        formatCurrencyAmount(
                                            item.foreignCurrency,
                                            item.foreignAmount,
                                        ),
                                    paymentType:
                                    item.transaction
                                        .paymentType,
                                }),
                            );
                        setExportCollectionData(data);
                        setAllCollection(payInData);
                        setLoading(false);
                    },
                );
            }
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (businessId !== null) {
            getAllCollection()
        }
    }, [businessId, collectionPageSize, collectionsPageNumber])

    const handleRowClick = (row: any) => {
        setTransactionDetail(row);
        setOpenDrawer(true);
    };

    const handleCollectionCardClick = (data: any) => {
        setOpenDrawer(true)
    }

    useEffect(() => {
        if (activeToggle === "Payout") {
            allPayoutData();
        } else if (activeToggle === "Collections") {
            getAllCollection();
        }
    }, [payoutRequests, accountMap, activeToggle]);

    const [openExportFilterModal, setOpenExportFilterModal] = useState<boolean>(false)
    

    const handleExportPermission = () => {
        if (hasDownloadPermission || role === "OWNER") {
            exportToXLSBalance(
                exportCollectionData,
                activeToggle,
                payoutRequests,
                accountMap,
            );
        } else {
            onShowErrorAlert("", "Access is denied");
        }
    };

    const handleXLSPermission = () => {
        if (hasDownloadPermission || role === "OWNER") {
            exportToXLSBalance(
                exportCollectionData,
                activeToggle,
                payoutRequests,
                accountMap,
            );
        } else {
            onShowErrorAlert("", "Access is denied");
        }
    };


    const filteredTransaction = allCollection?.filter(
        (item) =>
            Object.values(item).some(
                (val) =>
                    typeof val === "string" &&
                    val
                        .toLowerCase()
                        .includes(search.toLowerCase()),
            ),
    );

    const filteredPayoutTransaction =
        settlementsData?.filter((item) =>
            Object.values(item).some(
                (val) =>
                    typeof val === "string" &&
                    val
                        .toLowerCase()
                        .includes(search.toLowerCase()),
            ),
        );

    return (
        <Wrapper>
            <Loader
                isLoading={
                    settlementStatus === "loading" ||
                    isFiltering ||
                    loading
                }
            />
            <TitleParagraph>Collections <InfoTrigger onClick={() => setShowInfoModal(!showInfoModal)}><QuestionMark/></InfoTrigger></TitleParagraph>
           
            {showInfoModal && (
                <CustomModal
                    isModalVisible={showInfoModal}
                    onClose={() => setShowInfoModal(!showInfoModal)}
                    width="464px"
                    modalTitle="Collections"
                    theme="secondary"
                >
                    <InfoModal/>
                </CustomModal>
            )}

            <BreakDown>
                <CollectionSummaryCard 
                    theme="primary" 
                    title="Available balance" 
                    mainFigure={
                        businessCurrency
                            ? formatCurrencyCodeAmount(
                                businessCurrency,
                                Number(
                                    availableCollectionBalance ||
                                    0,
                                ),
                            )
                            : "0.00"
                    }
                    subline={`Ledger balance: ${
                        businessCurrency
                        ? formatCurrencyCodeAmount(
                            businessCurrency,
                            Number(
                                ledgerCollectionBalance ||
                                0,
                            ),
                        )
                        : "0.00"
                    }`}
                />

                <CollectionSummaryCard 
                    theme="secondary" 
                    title="Total collection today" 
                    mainFigure={
                        businessCurrency
                            ? formatCurrencyCodeAmount(
                                businessCurrency,
                                Number(
                                    todayCollectionBalance ||
                                    0,
                                ),
                            )
                            : "0.00"
                    }
                />

                <CollectionSummaryCard 
                    theme="tertiary" 
                    title="Rolling reserve wallet" 
                    mainFigure={
                        businessCurrency
                            ? formatCurrencyCodeAmount(
                                businessCurrency,
                                Number(
                                    collectionHoldBalance ||
                                    0,
                                ),
                            )
                            : "0.00"
                    }
                />

            </BreakDown>

            <BoxBody>
                <TitleFilter
                    search={search}
                    setSearch={setSearch}
                    showFilter={
                        settlementsData?.length ||
                        allCollection?.length
                    }
                    exportToCSV={handleExportPermission}
                    onXLSButtonClick={handleXLSPermission}
                    onRequestClick={onRequestClick}
                    onDateChange={onDateChange}
                    onStatusChange={onStatusChange}
                    onFilterButtonClick={
                        onFilterButtonClick
                    }
                    onResetButtonClick={onResetButtonClick}
                    disableRequest={settlementBalance < 0}
                    activeToggle={activeToggle}
                    setActiveToggle={setActiveToggle}
                />

                {activeToggle === "Collections" && (
                    <div>
                        {width > 767 ? (
                            <CustomTable
                                columns={
                                    allCollectionsColumn
                                }
                                handleRowClick={
                                    handleRowClick
                                }
                                dataSource={
                                    filteredTransaction
                                }
                                extraEmptyChildren={
                                    <EmptyBalanceDescription
                                        title="No collections yet"
                                        subtitle="All collection transactions will appear here."
                                    />
                                }
                                icon={<EmptyTableIcon/>}
                                currentPage={collectionsPageNumber}
                                totalItemCount={exportCollectionData?.totalCount}
                                onPageChange={onCollectionsPageChange}
                                pageSize={collectionPageSize}
                            />
                        ) : (
                            <MobileBody>
                                <CustomMobileBalanceTable
                                    settlementsData={filteredTransaction}
                                    BalanceListCard={BalanceListCard}
                                    currentPage={collectionsPageNumber}
                                    totalItemCount={exportCollectionData?.totalCount}
                                    onPageChange={onCollectionsPageChange}
                                    pageSize={collectionPageSize}
                                    pageSizeOptions={[10, 20, 50]}
                                    transactionType="Collections"
                                />
                            </MobileBody>
                        )}
                    </div>
                )}

                {activeToggle === "Payout" && (
                    <div>
                        {width > 767 ? (
                            <CustomTable
                                columns={tableColumns}
                                extraEmptyChildren={
                                    <EmptyBalanceDescription
                                        title="No payout yet"
                                        subtitle="All payout transactions will appear here."
                                    />
                                }
                                dataSource={
                                    filteredPayoutTransaction
                                }
                                icon={<EmptyTableIcon/>}
                                currentPage={pageNumber}
                                onPageChange={onPageChange}
                                pageSize={pageSize}
                            />
                        ) : (
                            <MobileBody>
                                <CustomMobileBalanceTable
                                    settlementsData={filteredPayoutTransaction}
                                    BalanceListCard={BalanceListCard}
                                    currentPage={pageNumber}
                                    onPageChange={onPageChange}
                                    pageSize={10}
                                    pageSizeOptions={[10, 20, 50]}
                                    transactionType="Payout"
                                />
                            </MobileBody>
                        )}
                    </div>
                )}

                <CustomModal
                    isModalVisible={showModal}
                    onClose={closeModal}
                    width="464px"
                >
                    <PayoutModal
                        onCloseModal={closeModal}
                        accounts={accountSelectOptions}
                        setRequestData={setRequestData}
                        onPreview={onPreview}
                        businessCurrency={businessCurrency}
                        minimumPayoutValue={
                            +minimumPayoutValue
                        }
                        getRequestFee={getRequestFee}
                        getBankId={setBankId}
                    />
                </CustomModal>

                {showPreview && (
                    <PreviewPayout
                        setShowModal={setShowModal}
                        setShowPreview={setShowPreview}
                        setShowOTP={setShowOTP}
                        accounts={accountSelectOptions}
                        requestData={requestData}
                        businessCurrency={businessCurrency}
                        fee={fee}
                        setLoading={setLoading}

                    />
                )}

                {showOTP && (
                    <PayoutOTP
                        setShowOTP={setShowOTP}
                        onRequestPayout={onRequestPayout}
                        setLoading={setLoading}

                    />
                )}
            </BoxBody>

            <DrawerContainer
                title="Transaction details"
                onClose={() => setOpenDrawer(false)}
                open={openDrawer}
                placement={"right"}
            >
                <TransactionDetails
                    transaction_id={
                        transactionDetail?.transaction_id
                    }
                    merchant_amount={
                        transactionDetail?.merchant_amount
                    }
                    reference={transactionDetail?.ref}
                    merchantAmount={
                        transactionDetail?.merchant_amount
                    }
                    payerAmount={
                        transactionDetail?.payer_amount
                    }
                    amountLessFess={
                        transactionDetail?.amount_less ||
                        "-"
                    }
                    currency={transactionDetail?.currency}
                    settledAmount={
                        transactionDetail?.settlement_amount ||
                        "-"
                    }
                    rate={transactionDetail?.rate}
                    date={transactionDetail?.date}
                />

                <CustomerDetails
                    email={
                        transactionDetail?.customer?.email
                    }
                    phone={
                        transactionDetail?.customer
                            ?.phone || "-"
                    }
                    method={
                        transactionDetail?.paymentType ||
                        "-"
                    }
                />
            </DrawerContainer>
        </Wrapper>
    );
};

export default Balances;
